import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import InfoIcon from '@mui/icons-material/Info'
import { AppBar, Grid, Hidden, Icon, Link as MaterialLink, Tab, Tabs, Toolbar } from '@mui/material'
import AppSettings, { App_Routes, Roles } from 'ApplicationSettings/ApplicationSettings'
import { ProfileContext } from 'Components/Auth/ProfileProvider'
import UserMenu from 'Components/UserMenu/UserMenu'
import logo from 'Resources/webmd-ignite-logo.svg'
import { AppFeatureDictionary, FeatureEnabled, useAuthState } from 'Store/AuthState'
import { useLocalizationState } from 'Store/LocalizationState'
import { Localization } from 'Utilities/Localization'
import { Feature } from 'Utilities/PermissionManager'
import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import style from './Header.module.scss'

function currentTab(location: any) {
  const path: string = location.pathname.toLowerCase()
  if (path.endsWith('/manage') || path.indexOf('contentitem') > 0) return 2
  else if (path.endsWith('/reports')) return 3
  else if (path.endsWith('/features')) return 0
  else if (path.includes('/embedding')) return 0
  //else if (path === '/new page here') return 2;
  else return 1
}

export interface UserInformation {
  email?: string
  firstName: string
  lastName: string
  logService: boolean
  contentFeedback: boolean
}

export default function Header() {
  const location = useLocation()
  const profile = useContext(ProfileContext)
  const { authState } = useAuthState()
  const loc = new Localization(useLocalizationState())
  const [tabValue, setTabValue] = useState(currentTab(location))
  const [applicationReady, setApplicationReady] = useState(true) //Assume true so we don't show the banner in error. Wait until auth state is loaded

  useEffect(() => {
    setTabValue(currentTab(location))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (authState.orgInfo) {
      setApplicationReady(
        (authState?.orgInfo?.subscriptions?.filter(
          s => s.active && s.name.toLowerCase() !== 'management'
        )?.length ?? 0) > 0
      )
    }
  }, [authState.orgInfo])

  const userInfo: UserInformation = {
    firstName: profile.firstName,
    lastName: profile.lastName,
    email: profile.email,
    logService: profile.logService,
    contentFeedback: profile.contentFeedback,
  }

  const tabs = [
    {
      label: loc.GetLocalized(Localization.Key.Browse),
      to: App_Routes.BROWSE,
      value: 1,
      condition:
        authState.features.indexOf(Feature.AdvancedSearch) > -1 ||
        authState.features.indexOf(Feature.DemoSearch) > -1,
    },
    {
      label: loc.GetLocalized(Localization.Key.Manage),
      to: App_Routes.MANAGE,
      value: 2,
      condition:
        FeatureEnabled(AppFeatureDictionary.CanUploadAndManage) &&
        authState.managementRoles!.findIndex((item: string) => item === Roles.ADMINISTRATOR) > -1,
    },
    {
      label: loc.GetLocalized(Localization.Key.Reports),
      to: App_Routes.REPORTS,
      value: 3,
      condition: authState.features.indexOf(Feature.ViewReports) > -1,
    },
  ]

  return (
    <>
      {!applicationReady && (
        <div className={style.configRequiredHeaderContainer}>
          <Grid
            direction='column'
            spacing={2}
            container
            className={style.configRequiredHeader}
            alignItems='center'
            justifyContent='flex-start'
          >
            <Grid item>
              <Icon>
                <InfoIcon className={style.configRequiredHeaderErrorIcon} />
              </Icon>
            </Grid>
            <Grid item container>
              <Grid item xs={12} className={style.configRequiredHeaderText}>
                <b>We’re still configuring the application for you.</b>
              </Grid>
              <Grid item className={style.configRequiredHeaderText}>
                You may find some features will not work until we’re done. 
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      <AppBar className={style.appBar} elevation={0} position='static'>
        <Toolbar>
          <Grid alignItems='center' container justifyContent='space-between'>
            <Grid alignItems='center' container item xs={4}>
              <MaterialLink
                aria-label='Open My Healthwise landing page'
                className={style.logoWrapper}
                data-qa='MyHealthwiseLink'
                href={AppSettings.MyHealthwiseIssuer}
              >
                <img alt='logo' className={style.logo} src={logo} />
              </MaterialLink>
            </Grid>

            <Grid container item justifyContent='left' xs={4}>
              <Tabs
                aria-label='nav tabs'
                className={style.tabs}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: 'red',
                    height: '4px',
                  },
                }}
                // Checks if the active tab is visible and passes false if not to avoid index errors
                value={tabs.find(t => t.value === tabValue)?.condition ? tabValue : false}
              >
                {tabs.map(tab => {
                  const { condition, ...tabProps } = tab
                  return (
                    condition && (
                      <Tab
                        {...tabProps}
                        key={tab.label}
                        className={classNames(style.tab, 'dark')}
                        component={Link}
                        tabIndex={0}
                      />
                    )
                  )
                })}
              </Tabs>
            </Grid>

            <div className={style.profile}>
              <div className={style.account}>
                <Grid container direction='row'>
                  <Hidden xsDown>
                    <Grid item>
                      <AccountCircleIcon className={style.accountIcon} />
                    </Grid>
                  </Hidden>

                  <Grid item>
                    <Grid container direction='column'>
                      <Grid item>
                        <span className={style.name}>{userInfo.firstName}</span>

                        <UserMenu user={userInfo} />
                      </Grid>

                      <Hidden xsDown>
                        <Grid item>
                          <div className={style.tool}>Content Management</div>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}
