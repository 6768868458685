import { Link, Tooltip } from '@mui/material'
import { useLocalizationState } from 'Store/LocalizationState'
import { Localization } from 'Utilities/Localization'
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'common.util'
import { FC } from 'react'
import style from './Footer.module.scss'

const Footer: FC = () => {
  const loc = new Localization(useLocalizationState())

  function Links() {
    return (
      <div className={style.links}>
        <Tooltip title={loc.GetLocalized(Localization.Key.PRIVACY_POLICY_TOOLTIP_TEXT)} arrow>
          <Link className={`${style.link} dark`} href={PRIVACY_POLICY_URL} target='_blank'>
            Privacy Notice
          </Link>
        </Tooltip>

        {` • `}

        <Tooltip title={loc.GetLocalized(Localization.Key.TERMS_OF_USE_TOOLTIP_TEXT)} arrow>
          <Link className={`${style.link} dark`} href={TERMS_OF_USE_URL} target='_blank'>
            Terms of Use
          </Link>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className={style.normalFooter}>
      <p>
        © 2024 - {new Date().getFullYear()} Ignite Healthwise, LLC{'   '}|
      </p>

      <Links />
    </div>
  )
}

export default Footer
